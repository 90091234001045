import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavRoad from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
  MessageParagraph,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Boyle's Law Calculator",
    link: "/boyles-law-calculator/",
  },
]
const seeAlsoArray = [
  "/charles-law-calculator",
  "/ohms-law-calculator",
  "/specific-heat-calculator",
  "/percentage-calculator",
]

function BoylesLawCalculator(props) {
  let [ErrorMessage, setErrorMessage] = useState("")

  let pressure1 = useRef("")
  let volume1 = useRef("")
  let pressure2 = useRef("")
  let volume2 = useRef("")
  let pressure1Unit = useRef("")
  let volume1Unit = useRef("")
  let pressure2Unit = useRef("")
  let volume2Unit = useRef("")

  // var mydiv = document.getElementById('myerror');
  var errors = function (pressure1, volume1, pressure2, volume2) {
    let emptyInput =
      "Please input three out of four numbers. We can't do chemistry without any numbers!"
    if (
      pressure1 === "" &&
      volume1 === "" &&
      pressure2 === "" &&
      volume2 === ""
    ) {
      setErrorMessage(emptyInput)
    } else if (
      pressure1 !== "" &&
      volume1 !== "" &&
      pressure2 !== "" &&
      volume2 !== ""
    ) {
      setErrorMessage(
        "Please only input three out of four numbers. We can't do any chemistry if you already have all the numbers!"
      )
    } else if (pressure1 === "" && volume1 === "" && pressure2 === "") {
      setErrorMessage(emptyInput)
    } else if (pressure1 === "" && volume1 === "" && volume2 === "") {
      setErrorMessage(emptyInput)
    } else if (pressure1 === "" && pressure2 === "" && volume2 === "") {
      setErrorMessage(emptyInput)
    } else if (volume1 === "" && pressure2 === "" && volume2 === "") {
      setErrorMessage(emptyInput)
    } else if (pressure1 === "" && volume1 === "") {
      setErrorMessage(emptyInput)
    } else if (pressure1 === "" && pressure2 === "") {
      setErrorMessage(
        "Please input three out of four numbers. We can't do chemistry claculation without any numbers!"
      )
    } else if (pressure1 === "" && volume2 === "") {
      setErrorMessage(emptyInput)
    } else if (volume1 === "" && pressure2 === "") {
      setErrorMessage(emptyInput)
    } else if (volume1 === "" && volume2 === "") {
      setErrorMessage(emptyInput)
    } else if (pressure2 === "" && volume2 === "") {
      setErrorMessage(emptyInput)
    } else if (pressure1 < 0 || volume1 < 0 || pressure2 < 0 || volume2 < 0) {
      setErrorMessage(
        "You can't have any negative numbers. It's impossible to have a negative pressure or volume!"
      )
    } else {
      return true
    }
  }

  function findVariable(pressure1, volume1, pressure2, volume2) {
    if (pressure1 === "") {
      return "pressure1"
    } else if (volume1 === "") {
      return "volume1"
    } else if (pressure2 === "") {
      return "pressure2"
    } else {
      return "volume2"
    }
  }

  var convertToAtm = function (unit, pressure) {
    if (unit === "torr") {
      return pressure / 760
    } else {
      return pressure
    }
  }

  var convertToL = function (unit, volume) {
    if (unit === "milliliters") {
      return volume / 1000
    } else {
      return volume
    }
  }

  var calculation = function (
    variable,
    pressure11,
    volume11,
    pressure22,
    volume22
  ) {
    if (variable === "pressure1") {
      return (pressure22 * volume22) / volume11
    } else if (variable === "volume1") {
      return (pressure22 * volume22) / pressure11
    } else if (variable === "pressure2") {
      return (pressure11 * volume11) / volume22
    } else {
      return (pressure11 * volume11) / pressure22
    }
  }

  var convertAnswer = function (
    variable,
    pressure1Unit,
    pressure2Unit,
    volume1Unit,
    volume2Unit,
    answer
  ) {
    if (variable === "pressure1" && pressure1Unit === "torr") {
      return answer * 760
    } else if (variable === "pressure2" && pressure2Unit === "torr") {
      return answer * 760
    } else if (variable === "volume1" && volume1Unit === "milliliters") {
      return answer * 1000
    } else if (variable === "volume2" && volume2Unit === "milliliters") {
      return answer * 1000
    } else {
      return answer
    }
  }

  var print = function (variable, answer) {
    if (variable === "pressure1") {
      pressure1.current.value = answer
    } else if (variable === "volume1") {
      volume1.current.value = answer
    } else if (variable === "pressure2") {
      pressure2.current.value = answer
    } else {
      volume2.current.value = answer
    }
  }

  var calculate = function () {
    let Xpressure1 = pressure1.current.value
    let Xvolume1 = volume1.current.value
    let Xpressure2 = pressure2.current.value
    let Xvolume2 = volume2.current.value

    let Xpressure1Unit = pressure1Unit.current.value
    let Xvolume1Unit = volume1Unit.current.value
    let Xpressure2Unit = pressure2Unit.current.value
    let Xvolume2Unit = volume2Unit.current.value

    var variable = findVariable(Xpressure1, Xvolume1, Xpressure2, Xvolume2)

    if (errors(Xpressure1, Xvolume1, Xpressure2, Xvolume2) === true) {
      Xpressure1 = convertToAtm(Xpressure1Unit, Xpressure1)
      Xvolume1 = convertToL(Xvolume1Unit, Xvolume1)
      Xpressure2 = convertToAtm(Xpressure2Unit, Xpressure2)
      Xvolume2 = convertToL(Xvolume2Unit, Xvolume2)

      var answer = calculation(
        variable,
        Xpressure1,
        Xvolume1,
        Xpressure2,
        Xvolume2
      )
      answer = convertAnswer(
        variable,
        Xpressure1Unit,
        Xpressure2Unit,
        Xvolume1Unit,
        Xvolume2Unit,
        answer
      )
      print(variable, answer)
    }
  }

  function reset() {
    pressure1.current.value = ""
    volume1.current.value = ""
    pressure2.current.value = ""
    volume2.current.value = ""
    setErrorMessage("")
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Boyle's Law Calculator - Calculate Mariotte’s law"
        description="Boyle's Law Calculator, Simple calculate Boyle's Law (Mariotte’s law), there is pressure 1, volume 1, and pressure 2, volume 2, just give us three values and wee will calculate for you the four value."
        keywords={[
          "Boyle's Law, Boyle's Law calculator, calculate Boyle's Law,also called Mariotte’s law, Mariotte’s law,experimental gas law,pressure of a gas.",
        ]}
      />
      <NavRoad listPages={navRoadArray} />
      <div className="container">
        <Title>&#9883; Boyle's Law Calculator</Title>
        <SubTitle>Boyle's Law</SubTitle>

        <Columns>
          <Column>
            <MessageParagraph color="red">{ErrorMessage}</MessageParagraph>
            <Field>
              <Control>
                <StaticButton>Pressure 1</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={pressure1} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="pressure-one"
                    ref={pressure1Unit}
                  >
                    <option value="atm">atm</option>
                    <option value="torr">Torr</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Volume 1</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={volume1} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="volumne-one"
                    ref={volume1Unit}
                  >
                    <option value="liters">L</option>
                    <option value="milliliters">mL</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Pressure 2</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={pressure2} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="pressure-two"
                    ref={pressure2Unit}
                  >
                    <option value="atm">atm</option>
                    <option value="torr">Torr</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Volume 2</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={volume2} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="volume-2"
                    ref={volume2Unit}
                  >
                    <option value="liters">L</option>
                    <option value="milliliters">mL</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <FlexDiv display="flex">
              <Button
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                value="Calculate"
                onClick={calculate}
              >
                Calculate
              </Button>
              <Button
                borderColor="#ea4336"
                color="#ea4336"
                hoverBorderColor="#ea4336"
                hoverBackgroundColor="#ea4336"
                hoverColor="white"
                value="Reset"
                onClick={reset}
              >
                Reset
              </Button>
            </FlexDiv>
          </Column>
          <Column>
            {/* <div style={{ maxWidth: "350px", margin: "auto" }}>
              <Img
                fluid={data.placeholderImage.childImageSharp.fluid}
                alt="boyles law calc"
              />
            </div> */}
            <FlexDiv maxWidth="400px" margin="auto">
              <SmallImg filename="boyles.png" alt="calculate boyles law" />
            </FlexDiv>
          </Column>
        </Columns>

        <br />

        <h4>Boyle's Law</h4>
        <p>
          Boyle’s law (Mariotte’s law), tells us how the pressure of gas leads
          to an increase as the volume of the container decreases. Boyle
          explained that the volume of air trapped by a liquid in the closed
          short limb of a tube reduced inexactly proportion to the pressure
          produced by the liquid in the long part of the tube.
        </p>

        <h4>Boyle's Law Formula</h4>
        <p>
          {/* the formula is the pressure (p) of a given quantity of gas
varies inversely with its volume (v) at constant temperature
i.e., in equation form, <strong>pv = k</strong>, a constant. */}
          the pressure varies inversely with its volume at a constant
          temperature.
          <br />
          The relationship for Boyle’s Law can be declared as follows: (P1, V1 =
          P2, V2) initial pressure P1, and volume V1 = pressure P2 and volume V2
          after the change.
          {/* The relationship for Boyle’s Law can be expressed as follows:
  P1V1 = P2V2, where P1 and V1 are the initial pressure and
  volume values, and P2 and V2 are the values of the pressure
  and volume of the gas after change. */}
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default BoylesLawCalculator
